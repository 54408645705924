<style lang="scss">
  @import './MyPlayLogin.scss';
</style>

<template>
    <div class="my-play-login" :class="hideCommunityOnCollapse ? 'hide-on-collapse' : ''">
        <a :href="registrationUrl" target="_blank" class="btn btn-sign-up">Registrati a <i class="my-play-label">MyPlay</i></a>
        <a v-on:click="$emit('toggleProfileModal')" href="#" class="btn btn-sign-in">Accedi <span class="sign-in-label">a <i class="my-play-label">MyPlay</i></span></a>
    </div>
</template>

<script>
export default {
  name: 'MyPlayLogin',
  props: {
    hideCommunityOnCollapse : {
      type: Boolean,
      default: true
    }
  },
  computed: {
    registrationUrl() {
      const { origin } = window.location;

      if (origin.indexOf('radiofreccia.it') !== -1){
        return "https://myplay.rtl.it/registrati/?broId=17";
      }

      if (origin.indexOf('radiozeta.it') !== -1){
        return "https://myplay.rtl.it/registrati/?broId=18";
      }

      return "https://myplay.rtl.it/registrati/?broId=1";
    }
  }
}
</script>